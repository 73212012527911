<template>
    <div class="d-flex align-items-center">
        <a class="pb-0 mb-0 mr-1" v-sanitize-href="link" >
            {{ sliceString(link, textLength) }}
        </a>
        <copy-link-button :invitationLink="link"/>
    </div>
</template> 

<script>
    import CopyLinkButton from '@/components/leads/CopyLinkButton.vue';
    import Modal from '@/components/ui/Modal.vue';
    import UiButton from '@/components/ui/buttons/Button';
    import UiIconButton from '@/components/ui/IconButton.vue';
    export default {
        name: "link-invitation",
        components: {
            CopyLinkButton,
            Modal,
            UiButton,
            UiIconButton,
        },
        props: {
            link: {
                type: String,
                required: true,
            },
            textLength: {
                type: Number,
                required: false,
                default: 25,
            },
        },
        methods: {
            sliceString(string, maxLength = 25) {
                if(typeof string === 'string' && string.length > maxLength ) {
                    return `${string.slice(0, maxLength)}...`;
                }
                return string;
            },
        }
    }
</script>
