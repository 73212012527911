<template>
    <ui-icon-button icon="far fa-copy" @click="copyToClipboard()" v-if="!copied" />
    <ui-icon-button icon="fas fa-check-circle text-success" v-else />
</template>
<script>
import UiIconButton from '@/components/ui/IconButton.vue';
export default {
    data() {
        return {
            copied: false,
        }
    },
    components: { UiIconButton },
    props: {
        invitationLink: {
            type: String,
            required: true,
        }
    },
    methods: {
        copyToClipboard() {
            this.copied = true;
            navigator.clipboard.writeText(this.invitationLink)
            this.$toast.success('Link copied to clipboard')
            setTimeout(()=>{
                this.copied = false;
            }, 1500)
        },
    },
}
</script>
