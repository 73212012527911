<template>
    <div class="user-info">
        <template v-if="info">
            <ui-list>
                <ui-list-item title="Name:">
                    <template #text>
                        {{ info.first_name }} {{ info.last_name || '' }}
                    </template>
                </ui-list-item>
                <ui-list-item v-if="info.paylink_alias" title="Payment link alias:" :text="info.paylink_alias" />
                <ui-list-item title="Email:">
                    <template #text>
                        <ui-link
                            v-if="info.email"
                            weight="medium"
                            :link="`mailto:${info.email}`"
                        >
                            {{ info.email }}
                        </ui-link>
                        <ui-badge v-else variant="secondary">
                            No information
                        </ui-badge>
                    </template>
                </ui-list-item>
                <ui-list-item title="Phone:">
                    <template #text>
                        <ui-link
                            v-if="info.phone"
                            weight="medium"
                            :link="`tel:+${info.phone}`"
                        >
                            {{ info.phone }}
                        </ui-link>
                        <ui-badge v-else variant="secondary">
                            No information
                        </ui-badge>
                    </template>
                </ui-list-item>
                <ui-list-item title="Website:">
                    <template #text>
                        <a v-sanitize-href="info.webpage" v-if="info.webpage" target="_blank">
                            {{ info.webpage }}
                        </a>
                        <ui-badge v-else variant="secondary">
                            No information
                        </ui-badge>
                    </template>
                </ui-list-item>
                <ui-list-item title="Average ticket:">
                    <template #text>
                        {{ info.average_ticket }}
                    </template>
                </ui-list-item>
                <ui-list-item title="Monthly transactions:">
                    <template #text>
                        {{ info.monthly_transactions }}
                    </template>
                </ui-list-item>
                <ui-list-item title="Link invitation:">
                    <template #text>
                        <link-invitation :link="info.link_invitation" :leadId="info.id" />
                    </template>
                </ui-list-item>
                <ui-list-item title="Contact:">
                    <template #text>
                        <contact-links :phone="info.phone || null" :email="info.email || null" />
                    </template>
                </ui-list-item>
            </ui-list>
        </template>
    </div>
</template>

<script>
import ContactLinks from '@/components/ui/ContactLinks';
import LinkInvitation from './LinkInvitation.vue';
import UiBadge from '@/components/ui/Badge';
import UiLink from '@/components/ui/Link.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    components: {
        ContactLinks,
        LinkInvitation,
        UiBadge,
        UiLink,
        UiList,
        UiListItem,
    },
    props: {
        info: {
            type: Object,
            default: () => {}
        },
    },
};
</script>
