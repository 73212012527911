<template>
    <div class="leads-info">
        <b-button class="mb-3" variant="light" @click="$router.back()">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <template v-if="userInfo">
            <h5 class="leads-info__name">
                {{ userInfo.first_name }} {{ userInfo.last_name || '' }}
            </h5>
            <p class="leads-leads-number">
                #{{ userInfo.id }}
            </p>
        </template>
        <div class="row">                   
            <template v-if="accountExists">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <ui-card-skeleton v-if="loadingUserInfo" />
                            <ui-card v-else title="Lead information">
                                <user-info :info="userInfo" />
                            </ui-card>
                        </div>
                        <div class="col-12 col-md-6">
                            <ui-card-skeleton v-if="loadingUserInfo" />
                            <ui-card v-else
                                title="Account comments"
                                description="To register a comment it is necessary to complete the following information."
                            >
                                <comments :account-id="leadId" is-lead :scope="'write_leads'" />
                            </ui-card>
                        </div>
                    </div>
                </div>                        
            </template>
            <div v-else class="col-md-12">
                <ui-card>
                    <empty-state img="disconnected.svg" title="The account don't have credentials." />
                </ui-card>
            </div>
        </div>
    </div>
</template>

<script>
import BButton from '@/components/bootstrap/components/Button/Button.vue';
import CardMoney from '@/components/balance/CardMoney.vue';
import Comments from '@/components/accounts/Comments.vue';
import EmptyState from '@/components/ui/EmptyState.vue';
import GatewayList from '@/components/accounts/GatewayList.vue';
import PricingList from '@/components/accounts/PricingList.vue';
import AccountTransactions from '@/components/accounts/AccountTransactions.vue';
import UiCard from '@/components/ui/Card.vue';
import UiCardSkeleton from '@/components/ui/skeleton/CardSkeleton.vue';
import UiStatCard from '@/components/ui/StatCard.vue';
import UiStatCardSkeleton from '@/components/ui/skeleton/StatCardSkeleton.vue';
import UiTabs from '@/components/ui/tabs/Tabs.vue';
import UiTab from '@/components/ui/tabs/Tab.vue';
import UserInfo from '@/components/leads/UserInfo.vue';
import WithheldAmount from '@/components/accounts/WithheldAmount.vue';

export default {
    components: {
        BButton,
        CardMoney,
        Comments,
        EmptyState,
        GatewayList,
        PricingList,
        AccountTransactions,
        UiCard,
        UiCardSkeleton,
        UiTabs,
        UiStatCard,
        UiStatCardSkeleton,
        UiTab,
        UserInfo,
        WithheldAmount,
    },
    data() {
        return {
            accountExists: true,
            leadId: this.$route.query.id,
            loadingUserInfo: false,
            summary: null,
            userInfo: null,
        };
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        async getUserInfo() {
            try {
                this.loadingUserInfo = true;
                const { data } = await this.$axios.get(`/_/leads/${this.leadId}`);
                this.userInfo = data;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
                this.accountExists = false;
            } finally {
                this.loadingUserInfo = false;
            }
        },
    },
};
</script>
